<template>
  <div>
    <div class="about">
      <div class="title">
        <p>公司简介</p>
      </div>
      <div class="about-content">
        <div class="img-box">
          <img src="@/assets/sys-3.png" alt="">
        </div>
        <div>
          <p class="txt">北京弘运保科技有限公司由弘泰通保投资集团于2020年发起设立，致力于帮助诚信经营的中小物流企业获得更低成本的资金支持，为中小物流企业的发展提供有力的供应链金融服务。</p>
          <p class="txt">公司成立之初即搭建了物流宝平台 ，采用“互联网+大数据+人工智能”的供应链金融技术体系，为中小物流企业提供一站式线上金融服务，让中小物流企业获得资金更加便捷高效。</p>
          <p class="txt">截止目前，物流宝累计服务客户已达100家，累计融资规模达到10亿。</p>
        </div>
      </div>
    </div>
    <div class="about">
      <div class="title">
        <p>联系我们</p>
      </div>
      <div class="about-content" style="justify-content: space-around;">
        <div class="map">
          <GMap width="607px" height="300px" @complete="onMapComplete" />
        </div>
        <div>
          <div class="about-message">
            <img src="@/assets/location.png" alt="">
            <p>地址：北京市朝阳区北辰东路8号院1号楼（北辰时代大厦）20层2008号</p>
          </div>
          <div class="about-message">
            <img src="@/assets/netebool.png" alt="">
            <p>商务：周经理  153 3456 7351</p>
          </div>
          <div class="about-message">
            <img src="@/assets/mail.png" alt="">
            <p>邮箱：marketing@56bao.com.cn</p>
          </div>
          <div class="about-message">
            <img src="@/assets/phone.png" alt="">
            <p>电话：010-64933553</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, ref, onMounted } from 'vue';
  import { GMap } from '../../Map/index'
  export default {
    name: 'about',
    components: { GMap },
    setup(props) {

      const imgUrl = ref('')
      const onMapComplete = (map) => {
        const marker = new AMap.Marker({
          zoom: 11,//级别
          position: new AMap.LngLat(116.40645, 39.99120),
          title: '北京'
        })
        map.add(marker);
        map.setFitView();
      }
      onMounted(() => {
        // init()
      })
      return {
        imgUrl,
        onMapComplete
      }
    }
  };
</script>

<style scoped lang="less">
  .about {
    width: 1349px;
    margin: 0 auto;
    .title {
      width: 100%;
      border-bottom: 1px solid#A0A0A0;
      p {
        width: 220px;
        padding: 88px 0 34px 0;
        font-size: 30px;
        font-weight: 500;
        color: #3E3A39;
        line-height: 26px;
        margin: 0 auto;
        border-bottom: 5px solid #2D68FF;;
        text-align: center;
      }
    }
    .about-message {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
      p {
        font-size: 16px;
        line-height: 26px;
      }
    }
    .img-box {
      width: 477px;
      height: 395px;
      box-shadow: 0px 11px 16px 0px rgba(0,0,0,0.13);
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 409px;
        height: 256px;
      }
    }
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 46px 0 200px 0;
      .txt {
        width: 734px;
        font-size: 16px;
        font-weight: 300;
        color: #3E3A39;
        line-height: 30px;
        margin-bottom: 15px;
      }
      .address {
        width: 450px;
      }
    }
  }
</style>
