<template>
  <div class="sys-content">
    <div class="sys-1">
      <div class="left">
        <p>专注物流垂直领域的</p>
        <p>金融科技SaaS平台</p>
      </div>
      <img src="@/assets/sys-1.png" alt="">
    </div>
    <div class="sys-2">
      <div class="left">
        <p>物流企业</p>
        <img src="@/assets/sys-3.png" alt="">
      </div>
      <div class="right">
        <p>数字化运营 + 便捷性融资</p>
        <p>助力物流企业提效增资健康发展</p>
        <div class="btn common-btn" @click="showPopUp">预约体验</div>
      </div>
    </div>
    <div class="sys-2 sys-3">
      <div class="right">
        <p>运通银企互联平台</p>
        <p>用数据降低风险，用科技提升效率， <br>助力金融机构资产安全便捷投放</p>
        <div class="btn common-btn" @click="showPopUp">预约体验</div>
      </div>
      <div class="left">
        <p>金融机构</p>
        <img src="@/assets/sys-5.png" alt="">
      </div>
    </div>

    <!-- 试算 -->
    <el-dialog v-model="qrcodeVis" title="预约体验" cancelButtonText="" confirmButtonText="">
      <div class="qrcode-wrap">
        <h2>扫码联系您的专属客户经理，帮助您免费预约体验</h2>
        <div class="qrcode-wrap-box">
          <div class="qrcodes">
            <img src="@/assets/1v1.png" alt="">
            <div class="txt">
              <p>周经理</p>
              <p>电话：153 3456 7351</p>
            </div>
          </div>
          <!-- <div class="qrcodes">
            <img src="@/assets/yan.png" alt="">
            <div class="txt">
              <p>严经理</p>
              <p>电话：177 8364 2912</p>
            </div>
          </div> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue';
  export default {
    name: 'service',
    setup() {
      const state = reactive({
        qrcodeVis: false
      })
      const showPopUp = () => {
        state.qrcodeVis = true
      }
      return {
        ...toRefs(state),
        showPopUp
      }
    }
  };
</script>

<style scoped lang="less">
  .sys-content {
    display: flex;
    flex-flow: column;
    align-items: center;
    .common-btn {
      user-select: none;
      cursor: pointer;
    }
  }
  .sys-1 {
    width: 100%;
    height: 543px;
    background: linear-gradient(0deg, #B9E3F8 0%, #DCECF9 100%);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .left {
      font-size: 35px;
      font-weight: bold;
      color: #2D68FF;
      line-height: 60px;
      margin-right: 300px
    }
    img {
      width: 545px;
      height: 339px;
    }
  }
  .sys-2 {
    width: 1349px;
    margin-top: 67px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .left {
      width: 477px;
      height: 395px;
      background: #FFFFFF;
      border: 1px solid #D3EDFA;
      box-shadow: 0px 11px 16px 0px rgba(0,0,0,0.13);
      border-radius: 30px;
      padding: 38px;
      p {
        width: 100%;
        font-size: 29px;
        font-weight: bold;
        color: #2D68FF;
        line-height: 40px;
        text-align: center;
        margin-bottom: 10px;
      }
      img {
        width: 409px;
        height: 256px;
      }
    }
    .right {
      width: 849px;
      height: 422px;
      background: url('@/assets/sys-2.png') no-repeat center;
      background-size: 100% 100%;
      margin: 20px -10px 0 0;
      position: relative;
      p:nth-of-type(1) {
        font-size: 29px;
        font-weight: bold;
        color: #2D68FF;
        line-height: 50px;
        position: absolute;
        top: 125px;
        left: 71px;
      }
      p:nth-of-type(2) {
        font-size: 19px;
        font-weight: 400;
        color: #595757;
        line-height: 33px;
        position: absolute;
        top: 184px;
        left: 71px;
      }
      .btn {
        width: 126px;
        height: 37px;
        background: #2D68FF;
        border-radius: 19px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 37px;
        position: absolute;
        top: 267px;
        left: 71px;
      }
    }
  }
  .sys-3 {
    .right {
      background: url('@/assets/sys-4.png') no-repeat center;
      background-size: 100% 100%;
      margin: 20px 0 0 -20px;
    }
  }
  // qrcode
  .qrcode-wrap {
    text-align: center;
    &-box {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 30px;
    }
    .qrcodes {
      display: flex;
      align-items: center;
      img {
        width: 160px;
        height: 160px;
      }
      .txt {
        text-align: left;
        line-height: 25px;
        margin-left: 15px;
      }
    }
  }
</style>
