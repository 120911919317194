
import axios from "axios";
import { ElMessage } from 'element-plus'
 
const initAxios = axios.create({
  timeout: 60 * 1000,
})
 
//请求拦截器
initAxios.interceptors.request.use((config) => {
  //在发送之前做点什么
  return config
}, (error) => {
  //对请求错误做点什么
  return error
})
 
//响应拦截器
initAxios.interceptors.response.use((response) => {
  if (response.status === 200 && response.data.success) {
    return response.data.res
  } else {
    ElMessage.error(response.data.errmsg);
    return false
  }
})
//导出
export default () => initAxios