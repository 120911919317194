//引入封装好的 service
import service from "./service";
 
//声明一个基础接口变量
let baseURL = 'api/'
let sufixUrl = `?logId=${Date.now()}`
 
//设置请求头（如果请求头统一的话可以在axios文件设置，则无须从这里传过去）
const header = {
  Accept: 'application/json;charset=UTF-8',
}
 
let _service={
  getCode(data) {
    const url = 'hittec-basic/sendSms/getCode';
    return service.post(baseURL+url+sufixUrl, data, header);
  },
  validateSmsCode(data) {
    const url = 'hittec-basic/sendSms/validSmsCode';
    return service.post(baseURL+url+sufixUrl, data, header);
  },
  addCrmTmsClue(data) {
    const url = 'hittec-crm/crmTmsClue/addCrmTmsClue';
    return service.post(baseURL+url+sufixUrl, data, header);
  }
}
 
 
//导出
export default _service