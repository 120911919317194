<template>
  <div>
    <div class="screen-item screen-one">
      <img class="banner" src="@/assets/m/banner.png" alt="">
      <div class="title">
        <p>建立企业的数据信用体系</p>
        <p>打造专业的金融科技平台</p>
      </div>
      <div class="count-box" v-if="activeIndex == 0">
        <div class="count flex-center-column">
          <img src="@/assets/yuan1.png" alt="">
          <p>{{number1}}</p>
          <p>累计服务企业客户</p>
        </div>
        <div class="count flex-center-column">
          <img src="@/assets/yuan2.png" alt="">
          <p>{{number2}}</p>
          <p>累计获得授信</p>
        </div>
        <div class="count flex-center-column">
          <img src="@/assets/yuan3.png" alt="">
          <p>{{number3}}</p>
          <p>累计完成融资</p>
        </div>
        <div class="count flex-center-column">
          <img src="@/assets/yuan4.png" alt="">
          <p>{{number4}}</p>
          <p>累计获得融资</p>
        </div>
      </div>
    </div>

    <div v-if="activeIndex == 0" class="screen-item screen-two">
			<h3>物流宝四大优势</h3>
      <div class="screen-two-box">
        <div class="why-box scale-2">
          <a class="s2-i1">
            <img src="@/assets/p2bg1.png" alt="">
            <h5><i></i>成本低，额度高</h5>
            <p>年化利率低于市场同类金融产品的平均利率水平且以实际用款天数计算，公平合理</p>
          </a>
          <a class="s2-i2">
            <img src="@/assets/p2bg2.png" alt="">
            <h5><i></i>免抵押，免担保</h5>
            <p>凭借有效合同、真实运单，即可融资无需资产抵押，<br>操作门槛低</p>
          </a>
          <a class="s2-i3">
            <img src="@/assets/p2bg3.png" alt="">
            <h5><i></i>随借随还，灵活用款</h5>
            <p>运单融资，项目合作，股权合作合同换签多种产品解决您的资金需求</p>
          </a>
          <a class="s2-i4">
            <img src="@/assets/p2bg4.png" alt="">
            <h5><i></i>线上操作，便捷高效</h5>
            <p>额度循环使用，可根据业务需要随借随还，资金用途<br>不作特别限制</p>
          </a>
        </div>
      </div>
    </div>


    <div v-if="activeIndex == 0" class="screen-item screen-seven flex-center-column">
      <h3>立即获得资金支持</h3>
      <div class="screen-seven-support flex-center">
        <div class="input-box flex-center-column">
          <div class="flex-center">
            <p>姓名：</p>
            <el-input
              placeholder="您的姓名"
              v-model="formVal.name"
              style="width: 3.45rem"
              size="small"
              >
              <template #prefix><i style="color: red">*</i></template>
            </el-input>
          </div>
          <div class="flex-center">
            <p>手机号：</p>
            <el-input
              placeholder="您的手机号"
              v-model="formVal.phone"
              style="width: 3.45rem"
              size="small"
              >
              <template #prefix><i style="color: red">*</i></template>
            </el-input>
          </div>
          <div class="flex-center">
            <p>公司名称：</p>
            <el-input
              placeholder="您的公司名称"
              v-model="formVal.company"
              style="width: 3.45rem"
              size="small"
              >
              <template #prefix><i style="color: red">*</i></template>
            </el-input>
          </div>
          <div class="flex-center">
            <p>资金需求：</p>
            <el-input
              placeholder="请描述您的资金需求"
              v-model="formVal.remarks"
              style="width: 3.45rem"
              size="small"
              >
              <template #prefix><i style="color: red">*</i></template>
            </el-input>
          </div>
        
          <div class="btn btn-confirm" @click="confirmClue">立即获得</div>
        </div>
        <img src="@/assets/car.png" alt="">
      </div>
    </div>

    <!-- <div v-if="activeIndex == 0" class="screen-item screen-three">
      <h3>物流宝产品服务</h3>
      <div class="screen-three-box">
        <div
          v-for="(item,index) in productData"
          :key="index"
          class="screen-three-box_img"
          @click="goDetails(index)"
        > 
          <img :src="item.img" class="img_left">
          <div class="bottom-box">
            <p>{{item.p1}}</p>
            <p>{{item.p2}}</p>
          </div>
        </div>
      </div>
    </div> -->

    <div v-if="activeIndex == 3" class="screen-item screen-five">
      <h3>什么企业可以申请{{productData[productIndex].p1}}</h3>
      <div class="screen-five-content">
        <div class="img-box-left flex-center">
          <img :src="productData[productIndex].banner" alt="">
        </div>
        <div class="msg-right">
          <div class="msg-right-item">
            <img src="@/assets/m/1_icon1.png" alt="">
            <p>企业的<br>上下货主</p>
            <p>{{productData[productIndex].t1}}</p>
          </div>
          <div class="msg-right-item">
            <img src="@/assets/m/1_icon2.png" alt="">
            <p>企业自身</p>
            <p>{{productData[productIndex].t2}}</p>
          </div>
          <div class="msg-right-item">
            <img src="@/assets/m/1_icon3.png" alt="">
            <p>货主系统</p>
            <p>{{productData[productIndex].t3}}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="activeIndex == 3" class="screen-item screen-six">
      <h3>产品要素</h3>
      <div class="screen-six-content">
        <div
          class="img-box flex-center-column"
          v-for="(item,index) in productData[productIndex].factor"
          :key="index"
        >
          <img :src="item.img" alt="">
          <p class="title">{{item.title}}</p>
          <p class="msg">{{item.msg}}</p>
        </div>
      </div>
    </div>

    <div v-if="activeIndex == 0" class="screen-item screen-four">
      <h3>物流宝遍布全国20多个省市地区</h3>
      <h4>联系电话：010-64933553</h4>
      <div class="lx-3 flex-center">
        <div class="qrcode">
          <img src="@/assets/1v1.png" alt="">
          <p>扫码1v1咨询</p>
        </div>
        <div class="qrcode">
          <img src="@/assets/wechat.png" alt="">
          <p>微信公众号</p>
        </div>
      </div>
    </div>
  </div>
  <!-- 验证码 -->
  <el-dialog v-model="codeNumVis" title="请输入短信验证码" width="7rem" @close="closeCodeNum">
    <template #header>
      <div class="my-header">
        <p style="font-size: 0.35rem">请输入短信验证码</p>
      </div>
    </template>
    <div class="validate-code-content">
      <div class="item">
        <span class="label">手机号</span>
        <el-input size="mini" v-model="formVal.phone" disabled style="width: 4rem"/>
      </div>
      <div class="item codeNum">
        <span class="label">短信验证码</span>
        <el-input size="mini" v-model="formVal.codeNum" maxlength="4" style="width: 4rem">
          <template #suffix>
            <span style="cursor: pointer; color: #606266" @click="sendCode">{{ codeData }}</span>
          </template>
        </el-input>
      </div>
    </div>
    <template #footer>
      <el-button @click="codeNumVis = false">我再想想</el-button>
      <el-button type="primary" @click="codeNumConfirm">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { defineComponent, reactive, toRefs, ref, unref, toRaw, onMounted } from 'vue';
  import img1 from '@/assets/p5c1.png';
  import img2 from '@/assets/p5c2.png';
  import img3 from '@/assets/p5c3.png';
  import img4 from '@/assets/p5c4.png';
  import img5 from '@/assets/p5c5.png';
  import img6 from '@/assets/p5c6.png';
  import img7 from '@/assets/p5c7.png';
  import img8 from '@/assets/p5c8.png';
  import img9 from '@/assets/p5c9.png';
  import img10 from '@/assets/p2bg5.png';
  import img11 from '@/assets/p2bg6.png';
  import i1 from '@/assets/m/2_i2.png';
  import i2 from '@/assets/m/2_i3.png';
  import i3 from '@/assets/m/2_i4.png';
  import i4 from '@/assets/m/2_i1.png';

  import _service from '@/api';

  import { ElPopover, ElMessage } from 'element-plus'

  export default defineComponent({
    name: 'home',
    emits: ['changeNav'],
    components: {},
    props: {
      activeIndex: Number
    },
    setup(props, { emit }) {
      const state = reactive({
        number1: '100+',
        number2: '¥1000+',
        number3: '3000笔',
        number4: '¥1000000000',
        productData: [
          {
            p1: '运单融资',
            p2: '暗保理 到账快 随借随还',
            banner: img10,
            t1: '国企央企及其子公司；\n上市公司及其3级以内控股子公司；\nAA发债企业及其3级以内子公司。',
            t2: '经营正常，负债合理，征信，\n无严重污点等。',
            t3: '货主需有物流系统，\n且企业使用该物流系统进行运输任务。',
            factor: [
              {img: img1, title: '保理类型', msg: '暗保理'},
              {img: img2, title: '融资人', msg: '合同物流企业'},
              {img: img3, title: '授信额度', msg: '参考运费规模和账期 确定，单个客户最高 授信1500万'},
              {img: img4, title: '融资期限', msg: '1年'},
              {img: img5, title: '融资比例', msg: '当期应收款的70%-80%'},
              {img: img6, title: '融资成本', msg: '11%左右'},
              {img: img7, title: '放款时点', msg: '运单完成即可申请放款'},
              {img: img9, title: '增信措施', msg: '法定代表人及大股东夫妇担保'},
            ],
            img: i1
          },
          {
            p1: '项目合作',
            p2: '要求低 使用便捷 个性定制',
            banner: img10,
            t1: '国企央企及其子公司；\n上市公司及其3级以内控股子公司；\nAA发债企业及其3级以内子公司。',
            t2: '经营正常，负债合理，征信，无严重污点等。',
            t3: '无特殊要求。',
            factor: [
              {img: img1, title: '保理类型', msg: '暗保理'},
              {img: img2, title: '融资人', msg: '合同物流企业'},
              {img: img3, title: '授信额度', msg: '依据项目规模'},
              {img: img4, title: '融资期限', msg: '承运合同期限'},
              {img: img5, title: '融资比例', msg: '当期应收款的90%'},
              {img: img6, title: '融资成本', msg: '10%左右'},
              {img: img7, title: '放款时点', msg: '运单完成即可申请放款'},
              {img: img9, title: '增信措施', msg: '法定代表人及大股东夫妇担保'},
            ],
            img: i2
          },
          {
            p1: '股权合作',
            p2: '深度绑定 风险共担',
            banner: img11,
            t1: '深度合作-历史经营稳健。',
            t2: '优质承运项目。',
            t3: '运费支持力度超过90%。',
            factor: [
              {img: img1, title: '金融产品', msg: '股权合作'},
              {img: img2, title: '融资人', msg: '物流企业，无其他特殊要求'},
              {img: img3, title: '授信额度', msg: '依据项目规模'},
              {img: img5, title: '融资比例', msg: '超过当期应收款的90%'},
              {img: img8, title: '产品特点', msg: '风险共担、收益共享'},
            ],
            img: i3
          },
          {
            p1: '合同换签',
            p2: '灵活高效 额度高 成本低',
            banner: img11,
            t1: '供应链金融-需优质承运项目。',
            t2: '运营你负责，资金我来找。',
            t3: '运费支持力度超过95%。',
            factor: [
              {img: img1, title: '金融产品', msg: '合同换签'},
              {img: img2, title: '融资人', msg: '供应链金融'},
              {img: img3, title: '授信额度', msg: '依据项目规模'},
              {img: img5, title: '融资比例', msg: '超过当期应收款的95%'},
              {img: img8, title: '产品特点', msg: '背靠背协议'},
            ],
            img: i4
          }
        ],
        productIndex: 0,
        codeNumVis: false,
      })

      const numberAni = ()=> {
        numBounce(88, 100, 1, '+', 1);
        numBounce(2.5, 5.5, 2, '亿', 1);
        numBounce(2990, 3000, 3, '笔', 1);
        numBounce(7, 10, 4, '亿', 1);
      }

      const numBounce = (initialNum, finishNum, index, otherText, frequency, time) => {
        var initial = initialNum;
        var intervalTime = 100
        if (time) {
          intervalTime = time;
        }
        var statisticsNum = setInterval(function() {
          state['number'+index] = initial + otherText;
          initial += frequency ? frequency : 1
          if (initial > finishNum) {
            clearInterval(statisticsNum)
          };
        }, intervalTime);
      }

      const goDetails = (type) => {
        document.body.scrollTop = 0;
        emit('changeNav', 3);
        state.productIndex = type
      }

      const formVal = ref({
        name: '',
        phone: '',
        company: '',
        remarks: '',
        codeNum: ''
      })

      const codeData = ref('立即发送');
      const codeToken = ref('');
      const timer = ref()

      const getCodeFun = async () => {
        const value = await _service.getCode({telephone: formVal.value.phone});
        codeToken.value = value;
        if (value.errno == 0 || value) {
          ElMessage.success('发送验证码已发送');
        }
      };
      // 发送验证码
      const sendCode = ()=> {
        if (codeData.value !== '立即发送') {
          return;
        }
        getCodeFun();
        let time = 179;
        codeData.value = time + 's';
        timer.value = setInterval(function () {
          if (time > 1) {
            time--;
            codeData.value = time + 's';
          } else {
            codeData.value = '立即发送';
            clearInterval(timer.value);
          }
        }, 1000);
      }

      const codeNumConfirm = async() => {
        if (!formVal.value.codeNum) {
          ElMessage.error('请输入验证码！');
          return;
        }
        const res = await _service.validateSmsCode({smsCode: formVal.value.codeNum, smsToken: codeToken.value});
        if(res){
          state.codeNumVis = false;
          _service.addCrmTmsClue({
            customerName: formVal.value.company,
            customerContactName: formVal.value.name,
            customerContactMobile: formVal.value.phone,
            source: 'website',
            remark: formVal.value.remarks
          }).then(res => {
            ElMessage.success('提交成功！');
          })
        }
      }

      const confirmClue = () => {
        if(!formVal.value.name) {
          ElMessage.error('请填写您的姓名');
          return;
        }
        if(!formVal.value.phone) {
          ElMessage.error('请填写您的手机号');
          return;
        }
        if(!/^1[3-9]\d{9}$/.test(formVal.value.phone)) {
          ElMessage.error('请填写正确的手机号');
          return;
        }
        if(!formVal.value.company) {
          ElMessage.error('请填写您的公司名称');
          return;
        }
        if(!formVal.value.remarks) {
          ElMessage.error('请填写您的备注');
          return;
        }
        state.codeNumVis = true;
      }

      const closeCodeNum = () => {
        clearInterval(timer.value);
        codeData.value = '立即发送';
        formVal.value.codeNum = ''
      }

      onMounted(()=> {
        numberAni();
      })

      return {
        ...toRefs(state),
        goDetails,
        formVal,
        sendCode,
        codeData,
        codeNumConfirm,
        confirmClue,
        closeCodeNum
      }
    },
  });
</script>

<style lang="less" scoped>
  .screen-one {
    scroll-snap-align: end!important;;
    position: relative;
    .banner {
      width: 100%;
    }
    .title {
      position: absolute;
      left: 0.64rem;
      top: 0.7rem;
    }
    p {
      font-size: 0.24rem;
      font-weight: 500;
      color: #595757;
      line-height: 0.42rem;
    }
  }

  .count-box {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.49rem auto;
    left: 50%;
    .count {
      img {
        width: 0.9rem;
        height: 0.9rem;
      }
      p:nth-of-type(1) {
        font-size: 0.30rem;
        line-height: 0.35rem;
        font-weight: 400;
        color: #898989;
        margin-top: 0.24rem;
        text-align: center;
      }
      p:nth-of-type(2) {
        font-size: 0.18rem;
        line-height: 0.23rem;
        font-weight: 400;
        color: #898989;
        margin-top: 0.1rem;
      }
    }
  }

 .screen-two {
    width: 100%;
    margin-top: 1rem;
    &-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.42rem;
    }
  }

  .screen-two .why-box {
    box-shadow: 0 0 0.3rem #dbdbdb;
    border-radius: 0.10rem;
    overflow: hidden;
    width: 6.39rem;
    // margin: 65px 146px 65px 0;
  }

  body .screen-two .why-box a {
    width: 3.18rem;
    height: 1.92rem;
    float: left;
    position: relative;
  }
  body .screen-two .why-box {
    a:nth-of-type(1) {
      margin: 0 0.03rem 0.03rem 0;
      h5 {
        padding-left: 0.45rem;
      }
      h5 i {
        position: absolute;
        width: 0.28rem;
        height: 0.28rem;
        background: url('@/assets/p2i1.png') no-repeat;
        background-size: 100%;
        left: 0.1rem;
        top: 0.03rem;
      }
    }
    a:nth-of-type(2) {
      margin: 0 0 0.03rem 0;
      h5 {
        padding-left: 0.45rem;
      }
      h5 i {
        position: absolute;
        width: 0.28rem;
        height: 0.28rem;
        background: url('@/assets/p2i2.png') no-repeat;
        background-size: 100%;
        left: 0.1rem;
        top: 0.03rem;
      }
    }
    a:nth-of-type(3) {
      margin: 0 0.03rem 0 0;
      h5 i {
        position: absolute;
        width: 0.28rem;
        height: 0.28rem;
        background: url('@/assets/p2i4.png') no-repeat;
        background-size: 100%;
        left: 0.07rem;
        top: 0.03rem;
      }
    }
    a:nth-of-type(4) {
      h5 i {
        position: absolute;
        width: 0.28rem;
        height: 0.28rem;
        background: url('@/assets/p2i3.png') no-repeat;
        background-size: 100%;
        left: 0.07rem;
        top: 0.03rem;
      }
    }
  }

  .screen-two .why-box a img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }

  .screen-two .why-box a h5 {
    font-size: 0.18rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -1rem;
    width: 2.1rem;
    height: 0.32rem;
    display: inline-block;
    line-height: 0.35rem;
    background-color: #fff;
    z-index: 1;
    border-radius: 0.1rem 0.1rem 0 0;
    padding-left: 0.38rem;
    color: #595757;
  }

  .screen-two .why-box p {
    display: none;
  }
  .why-box a:hover {
    background: linear-gradient(220.55deg, #DDE4FF 0%, #8DA2EE 100%);
  }
  .screen-two .why-box a:hover img {
    display: none;
}

  .screen-two .why-box a:hover h5 {
      width: 100%!important;
      margin-left: -0.7rem!important;
      padding: 0 !important;
      width: 100%;
      position: relative;
      left: 0.7rem;
      top: -0.45rem;
      border-radius: 0;
      font-size: 0.28rem;
      color: #fff;
      background: transparent;
      text-align: center;
  }

  .screen-two .why-box a:hover h5 i {
      display: none;
  }

  .screen-two .why-box a:hover h5:before,.screen-two .why-box a:hover h5:after{
      display: none;
  }

  .screen-two .why-box a:hover p {
      display: block;
      font-size: 0.2rem;
      padding: 0.1rem;
      line-height: 175%;
      color: #fff;
      text-align: center;
      margin-top: -.55rem;
  }

  .screen-three {
    margin-top: 0.85rem;
    width: 100%;
    h2 {
      font-size: 35px;
      font-weight: bold;
      color: #595757;
      line-height: 60px;
      text-align: center;
      margin-bottom: 14px;
    }
    &-box {
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      &_img {
        width: 6.35rem;
        height: 1.5rem;
        border-radius: 0.1rem;
        display: flex;
        box-shadow: 0.05rem 0.05rem 0 rgba(109, 111, 138,0.37);
        justify-content: flex-start;
        align-items: center;
        margin-top: 0.39rem;
        padding-left: 0.28rem;
        .bottom-box {
          color: #4A4B70;
          text-align: center;
          margin-left: 0.6rem;
          p:nth-of-type(1) {
            font-size: 0.29rem;
            font-weight: bold;
            text-align: left;
          }
          p:nth-of-type(2) {
            font-size: 0.18rem;
            margin-top: 20px;
          }
        }
      }
      &_img:nth-of-type(1) {
        background-color: #FFFFED;
        .img_left {
          width: 1.58rem;
          height: 1.31rem;
        }
      }
      &_img:nth-of-type(2) {
        background-color: #EDFFF5;
        .img_left {
          width: 1.58rem;
          height: 1.46rem;
        }
      }
      &_img:nth-of-type(3) {
        background-color: #D9F1FD;
        padding-left: 0;
        .img_left {
          width: 2.6rem;
          height: 1.66rem;
        }
        .bottom-box {
          margin-left: -0.1rem;
        }
      }
      &_img:nth-of-type(4) {
        background-color: #D9E5FD;
        .img_left {
          width: 2.26rem;
          height: 1.4rem;
        }
        .bottom-box {
          margin-left: -0.1rem;
        }
      }
    }
  }

  .screen-four {
    margin-top: 0.85rem;
    .lx-3 {
      width: 100%;
      margin-top: 0.4rem;
      .qrcode {
        img {
          width: 2rem;
          height: 2rem;
        }
        p {
          font-size: 0.24rem;
          text-align: center;
        }
      }
      .qrcode:nth-of-type(2) {
        margin-left: 0.6rem;
      }
    }
  }

  .screen-five {
    width: 100%;
    margin: 0.5rem auto;
    &-content {
      display: flex;
      flex-flow: column;
      align-items: center;
      .img-box-left {
        width: 6.31rem;
        height: 3.66rem;
        border-radius: 0.2rem;
        background: linear-gradient(0deg, #B9E3F8 0%, #DCECF9 100%);
        margin: 0.36rem 0;
        img {
          width: 4.62rem;
          height: 3rem;
        }
      }
      .msg-right {
        margin-left: 0.2rem;
        &-item {
          width: 6.06rem;
          height: 1.42rem;
          border: 1px solid #FB9B00;
          border-radius: 0.1rem;
          display: flex;
          align-items: center;
          margin-bottom: .34rem;
          position: relative;
          img {
            width: 0.8rem;
            height: 0.8rem;
            margin-left: -0.4rem;
          }
          p:nth-of-type(2) {
            font-size: 0.18rem;
            color: #595757;
            line-height: 0.3rem;
            margin-left: 1.65rem;
            white-space: pre;
          }
          p:nth-of-type(1) {
            position: absolute;
            top: 0.48rem;
            left: 0.6rem;
            font-size: 0.26rem;
            font-weight: 500;
            color: #595757;
            line-height: 0.3rem;
          }
        }
      }
    }
  }

  .screen-six {
    width: 100%;
    margin-top: 0.86rem;
    &-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-left: 0.45rem;
      margin-top: 0.3rem;
      .img-box {
        width: 1.46rem;
        // height: 2.4rem;
        border-radius: 0.25rem;
        background: linear-gradient(0deg, #B9E3F8 0%, #bed9ff 100%);
        margin: 0.1rem 0.07rem 0 0.05rem;
        padding: 0.2rem 0.1rem;
        img {
          width: 0.51rem;
          height: 0.57rem;
        }
        .title {
          font-size: 0.17rem;
          font-weight: 500;
          color: #1C43C5;
          line-height: 0.2rem;
          text-align: center;
          margin-top: 0.18rem;
          margin-bottom: 0.14rem;
        }
        .msg {
          font-size: 0.15rem;
          // transform: scale(0.8);
          font-weight: 300;
          color: #3E3A39;
          line-height: 0.25rem;
          width: 100%;
          height: 1.1rem;
          text-align: center;
        }
      }
    }
  }

  .screen-seven {
    width: 100%;
    margin-top: 0.86rem;
    &-support {
      width: 6.35rem;
      height: 4.49rem;
      background: url('@/assets/p5bg.png') no-repeat center;
      background-size: 100% 100%;
      position: relative;
      border-radius: 0.2rem;
      margin-top: 0.4rem;
    // padding: 81px 90px 107px 90px;
    }
    img {
      width: 1.73rem;
      height: 0.83rem;
      position: absolute;
      right: 0.1rem;
      bottom: 0.1rem;
    }
    .input-box {
      &>div {
        margin-bottom: 0.17rem;
        p {
          font-size: 0.18rem;
          color: #fff;
          font-weight: 500;
          text-align: left;
          width: 1.5rem;
        }
      }
      .btn-confirm {
        width: 1.4rem;
        height: 0.6rem;
        background: #F19149;
        border: 1px solid #FFFFFF;
        border-radius: 0.05rem;
        user-select: none;
        color: #fff;
        text-align: center;
        line-height: 0.6rem;
        font-size: 0.2rem;
        margin-top: 0.15rem;
      }
    }
  }

  .screen-item {
    h3 {
      font-size: 0.35rem;
      font-weight: bold;
      color: #595757;
      line-height: 0.7rem;
      text-align: center;
    }
    h4 {
      font-size: 0.18rem;
      color: #595757;
      text-align: center;
      line-height: 0.4rem;
    }
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex-center-column {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  // qrcode
  .qrcode-wrap {
    text-align: center;
    &-box {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 30px;
    }
    .qrcodes {
      display: flex;
      align-items: center;
      img {
        width: 160px;
        height: 160px;
      }
      .txt {
        text-align: left;
        line-height: 25px;
        margin-left: 15px;
      }
    }
  }
  // codeNum
  .validate-code-content {
    .item {
      display: flex;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 0.2rem;

      .label {
        display: inline-flex;
        width: 2rem;
        align-items: center;
      }

      &.codeNum {
        position: relative;

        .unit {
          position: absolute;
          top: 6px;
          right: 10px;
          cursor: pointer;
        }
      }
    }
  }
</style>
