import { createApp } from 'vue';
import App from './App.vue';
import './design/index.less';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import router from './router/index';
import { createGuard } from './router/index';
import '@/common/rem'
import VConsole from 'vconsole';

const app = createApp(App)

app.use(ElementPlus)
app.use(router)
createGuard(router);

app.mount('#app');