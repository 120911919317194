<template>
  <router-view />
</template>

<script>
import { defineComponent, reactive, ref, unref } from 'vue';
import homePage from './components/web/homePage.vue'
import mHomePage from './components/mobile/homePage.vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: 'App',
  components: {
    homePage,
    mHomePage
  },
  setup() {
    const router = useRouter()
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    if(isMobile) {
      router.push('/m');
    }else {
      router.push('/pc');
    }
  }
})
</script>

<style>
</style>
