import {
  h,
  ref,
  shallowRef,
  onMounted,
  unref,
  onUnmounted,
  watch,
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'GMap',
  props: {
    width: String,
    height: String,
    // 地图中心点，默认西安
    center: {
      type: Array
    },
    // 缩放级别，默认14
    zoom: {
      type: Number,
      default: () => 14,
    },
    plugins: {
      type: Array
    },
  },
  emits: ['complete'],
  setup(props, { attrs, emit, expose }) {
    const mapRef = shallowRef();
    const containerRef = ref();

    onMounted(() => {
      mapRef.value = new AMap.Map(unref(containerRef), {
        ...attrs,
        center: props.center,
        zoom: props.zoom,
      });
      if (props.plugins?.length) {
        // 添加控件
        AMap.plugin(props.plugins, function () {
          mapRef.value.on('complete', () => emit('complete', unref(mapRef)));
        });
      } else {
        mapRef.value.on('complete', () => emit('complete', unref(mapRef)));
      }
    });

    onUnmounted(() => mapRef.value?.destroy());

    watch(
      () => props.center,
      (val) => mapRef.value?.setCenter(val, true)
    );
    watch(
      () => props.zoom,
      (val) => mapRef.value?.setZoom(val, true)
    );

    expose({ map: mapRef });
    return () =>
      h('div', { ref: containerRef, style: { width: props.width, height: props.height } });
  },
});
